<template>
  <div class="bg-gray-main pb-16 mb-16">
    <div class="container">
      <div class="flex justify-center">
        <img src="@/assets/404.png" alt="Page Not Found" />
      </div>
      <div class="text-center mt-8">
        <h1 class="text-3xl font-bold">Oops! Page not found!</h1>
        <p class="mt-6 lg:w-1/2 mx-auto">
          We are very sorry for inconvenience. It looks like you’re trying to
          access a page that hase been deleted or never even existed.
        </p>
      </div>
      <div class="mt-6 flex justify-center mt-10">
        <Button
          text="Go to Home"
          :icon="['fas','home']"
          @click="() => this.$router.push({ name: 'Home' })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/core/Button";

export default {
  name: "PageNotFound",
  components: { Button },
};
</script>

<style>
</style>